<template>
    <div>
        <div data-aos="fade-up" class="pt-2">
            <h2 class="subtitle text-center" v-if="$store.state.lang == 'es'">Declaración</h2>
            <h2 class="subtitle text-center" v-else>Statement</h2>
        </div>

        <section class="container card" >
            <Transition name="fade" appear >
                <div class="row"  >
                    <div class="col-12 col-md-8 m-auto  p-5" >
                        <p v-if="$store.state.lang == 'es'" style="line-height:1.5rem" class="text-justify" v-html="content.statement_es"></p>
                        <p v-else v-html="content.statement_en" style="line-height:1.5rem" class="text-justify" ></p>
                    </div>
                       
                </div>
            </Transition>
        </section>

    </div>
</template>

<script >
import moment from 'moment';
import axios from 'axios'
import {_} from 'vue-underscore';
export default {
    name: 'ArtistComponent',

    data(){
        return{
            artist: 'Marina SaMont',
            profesional: {},
            exhibitions: {},
            studies: {},
            books:{},
            press:{},
            content:[]
        }
    },
    created(){
        this.getArtistData()
        this.getExhibitions()
        this.getProfesional()
        this.getStudies()
        this.getBooks()
        this.getPress()
    },
  
    methods:{
        format(date,format){
            return moment(date).format(format)
        },

        getArtistData(){
            axios.get(this.$url+'/artist',{
                headers: {'Content-Type' : 'application/json'}
            })
            .then((res)=>{
                this.content = res.data
            }).catch((err)=>{
                console.log(err)
            })
        },
        getExhibitions(){
            axios.get(this.$url+'/exhibitionsp',{
                headers: {'Content-Type' : 'application/json'}
            })
            .then((res)=>{
                this.exhibitions = res.data
            }).catch((err)=>{
                console.log(err)
            })
        },
        getProfesional(){
            axios.get(this.$url+'/profesional',{
                headers: {'Content-Type' : 'application/json'}
            })
            .then((res)=>{
                this.profesional = res.data
            }).catch((err)=>{
                console.log(err)
            })
        },
        getStudies(){
            axios.get(this.$url+'/studies',{
                headers: {'Content-Type' : 'application/json'}
            })
            .then((res)=>{
                this.studies = res.data
            }).catch((err)=>{
                console.log(err)
            })
        },
        getBooks(){
            axios.get(this.$url+'/books',{
                headers: {'Content-Type' : 'application/json'}
            })
            .then((res)=>{
                this.books = res.data
            }).catch((err)=>{
                console.log(err)
            })
        },
        getPress(){
            axios.get(this.$url+'/press',{
                headers: {'Content-Type' : 'application/json'}
            })
            .then((res)=>{
                this.press = res.data
            }).catch((err)=>{
                console.log(err)
            })
        }
    }
}
</script>
        
  